import { Button, Check, ColorIcon, DistributionIcon, Edit, SettingsVariantIcon } from '@reply-pro/component-library'
import { useLastSaveTime, useSurveySetupButtons } from './hooks'
import './SurveySetupButtons.scss'
import { useState } from 'react'
import SurveyDistributionModal from '../../SurveyDistributionModal'
import PostSurveyActionsModal from '../PostSurveyActionsModal'
import ManageSurveyThemeModal from '../ManageSurveyThemeModal'

const MODALS = {
  DISTRIBUTION: 'distribution',
  ACTIONS: 'actions',
  THEME: 'theme'
}

interface SurveySetupButtonsProps {
  id?: string
  surveyType: string
  browserId?: string
  isEditingDetails?: boolean
  toggleIsEditingDetails: () => void
}

function SurveySetupButtons ({ id, surveyType, browserId, isEditingDetails, toggleIsEditingDetails } :SurveySetupButtonsProps) {
  const [modalType, setModalType] = useState<string | null>(null)

  const openModal = (type: string) => {
    setModalType(type)
  }

  const closeModal = () => {
    setModalType(null)
  }

  const { saveSurveyQuestions, localData, isSavingChanges, pendingSyncChanges } = useSurveySetupButtons(browserId, id)
  const { timeSinceLastSync } = useLastSaveTime()

  return (
    <>
      <div className='survey-setup-buttons'>
        <span className='save-timestamp'>{timeSinceLastSync || ''}</span>
        <Button className='save-button' disabled={!localData || isSavingChanges || !pendingSyncChanges || isEditingDetails} onClick={saveSurveyQuestions}><Check /> Save Changes</Button>
        <Button stroked disabled={!localData || isSavingChanges || isEditingDetails} onClick={toggleIsEditingDetails}><Edit className='gray-icon' /> Edit Details</Button>
        <Button className='distribution-button' disabled={!id || isEditingDetails} stroked onClick={() => openModal(MODALS.DISTRIBUTION)}><DistributionIcon className='gray-icon' /> Distribution</Button>
        <Button disabled={!id || !localData?.pages || isEditingDetails} stroked onClick={() => openModal(MODALS.THEME)}><ColorIcon className='gray-icon' /> Theme</Button>
        <Button disabled={!id || !localData?.pages || isEditingDetails} stroked onClick={() => openModal(MODALS.ACTIONS)}><SettingsVariantIcon className='gray-icon' /> Post {surveyType} Actions</Button>
      </div>

      {modalType === MODALS.DISTRIBUTION && (
        <SurveyDistributionModal surveyType={surveyType} showModal toggleModalOpen={closeModal} id={id || ''} />
      )}

      {modalType === MODALS.ACTIONS && (
        <PostSurveyActionsModal showModal toggleModalOpen={closeModal} id={id || ''} surveyType={surveyType} />
      )}
      {modalType === MODALS.THEME && (
        <ManageSurveyThemeModal showModal toggleModalOpen={closeModal} id={id || ''} />
      )}
    </>
  )
}

export default SurveySetupButtons
