import { createSlice } from '@reduxjs/toolkit'
import { addReportCollection, addReportPage, addReportSection, editReportPage, editReportPageUsers, removeReportCollection, removeReportPage, removeReportSection, retrieveReportCollectionDetail, retrieveReportCollectionsList } from './settingsReducers'
import { settingsState } from './settingsTypes'
import { createUsersIndexedById } from 'utils/users'

const initialState: settingsState = {
  reportCollectionDetail: undefined,
  reportCollectionDetailLoading: false,
  reportPageUsers: {},
  reportPageUserGroups: {},
  reportCollections: {
    data: undefined,
    loading: false
  },
  reportingReordering: undefined,
  sortedReportingData: undefined,
  successMessage: null,
  errorMessage: null,
  loadingMessage: null,
  warningMessage: null
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setReportPageUsers: (state, action) => { state.reportPageUsers = createUsersIndexedById(action.payload) },
    addUserToReportPage: (state, action) => {
      const { id, ...user } = action.payload
      state.reportPageUsers = { ...state.reportPageUsers, [id]: user }
    },
    removeUserFromReportPage: (state, action) => {
      const { [action.payload]: omit, ...reportPageUsers } = state.reportPageUsers
      state.reportPageUsers = reportPageUsers
    },
    setReportPageUserGroups: (state, action) => { state.reportPageUserGroups = createUsersIndexedById(action.payload) },
    setReportReordering: (state, action) => {
      state.reportingReordering = action.payload
      if (action.payload === undefined) {
        state.sortedReportingData = undefined
      }
    },
    storeReportingOrderData: (state, action) => {
      state.sortedReportingData = action.payload
    },
    addUserGroupToReportPage: (state, action) => {
      const { id, ...userGroup } = action.payload
      state.reportPageUserGroups = { ...state.reportPageUserGroups, [id]: userGroup }
    },
    removeUserGroupFromReportPage: (state, action) => {
      const { [action.payload]: omit, ...reportPageUserGroups } = state.reportPageUserGroups
      state.reportPageUserGroups = reportPageUserGroups
    },
    closeNotifications: (state) => {
      state.successMessage = null
      state.errorMessage = null
      state.loadingMessage = null
      state.warningMessage = null
    },
    openNotificationSuccess: (state, action) => {
      state.successMessage = action.payload
    },
    openNotificationLoading: (state, action) => {
      state.loadingMessage = action.payload
    },
    openNotificationError: (state, action) => {
      state.errorMessage = action.payload
    }
  },
  extraReducers: ({ addCase }) => {
    addCase(retrieveReportCollectionsList.pending, (state, action) => {
      state.reportCollections.loading = true
    })

    addCase(retrieveReportCollectionsList.fulfilled, (state, action) => {
      state.reportCollections.loading = false
      state.reportCollections.data = action.payload
      if (action.payload.reportCollections?.edges.length < 1) {
        state.reportCollectionDetail = undefined
      }
    })

    addCase(retrieveReportCollectionsList.rejected, (state, action) => {
      state.reportCollectionDetail = undefined
    })
    addCase(retrieveReportCollectionDetail.pending, (state, action) => {
      state.reportCollectionDetailLoading = true
    })

    addCase(retrieveReportCollectionDetail.fulfilled, (state, action) => {
      if (action.meta.arg === action.payload.reportCollection.id) {
        state.reportCollectionDetail = action.payload.reportCollection
        state.reportCollectionDetailLoading = false
      }
    })

    addCase(retrieveReportCollectionDetail.rejected, (state, action) => {
      state.reportCollections.data = undefined
      state.reportCollectionDetailLoading = false
    })

    addCase(addReportCollection.fulfilled, (state, action) => {
      state.successMessage = 'Report Collection Added'
    })

    addCase(addReportCollection.rejected, (state, action) => {
      state.errorMessage = 'We encountered a problem while creating the Report Collection. Please try again.'
    })

    addCase(addReportPage.fulfilled, (state, action) => {
      state.successMessage = 'Report Page Added'
    })

    addCase(addReportPage.rejected, (state, action) => {
      state.errorMessage = 'We encountered a problem while creating the Report Page. Please try again.'
    })

    addCase(addReportSection.fulfilled, (state, action) => {
      state.successMessage = 'Report Section Added'
    })

    addCase(addReportSection.rejected, (state, action) => {
      state.errorMessage = 'We encountered a problem while creating the Report Section. Please try again.'
    })

    addCase(removeReportCollection.fulfilled, (state, action) => {
      state.successMessage = 'Report Collection deleted'
    })

    addCase(removeReportCollection.rejected, (state, action) => {
      state.errorMessage = 'We encountered a problem while deleting the Report Collection. Please try again.'
    })

    addCase(removeReportSection.fulfilled, (state, action) => {
      state.successMessage = 'Report Section deleted'
    })

    addCase(removeReportSection.rejected, (state, action) => {
      state.errorMessage = 'We encountered a problem while deleting the Report Section. Please try again.'
    })

    addCase(removeReportPage.fulfilled, (state, action) => {
      state.successMessage = 'Report Page deleted'
    })

    addCase(removeReportPage.rejected, (state, action) => {
      state.errorMessage = 'We encountered a problem while deleting the Report Page. Please try again.'
    })

    addCase(editReportPage.fulfilled, (state, action) => {
      state.successMessage = 'Report Page updated'
    })

    addCase(editReportPageUsers.fulfilled, (state, action) => {
      state.successMessage = 'Report Page users updated'
    })

    addCase(editReportPageUsers.rejected, (state, action) => {
      state.errorMessage = 'We encountered a problem while updating the Report Page users. Please try again.'
    })
  }
})

export const {
  setReportPageUsers,
  addUserToReportPage,
  removeUserFromReportPage,
  setReportPageUserGroups,
  addUserGroupToReportPage,
  removeUserGroupFromReportPage,
  closeNotifications,
  openNotificationSuccess,
  openNotificationLoading,
  openNotificationError,
  setReportReordering,
  storeReportingOrderData
} = settingsSlice.actions
export default settingsSlice.reducer
