/* eslint-disable react/jsx-closing-tag-location */
import ManageReportsCollections from './ManageReportsCollections'
import CollectionDetail from './CollectionDetail'
import ManageReportsContextRouter from './ManageReportsContextRouter'
import { SettingsPageStructure, SettingsHeader, Reports, Button, SettingsStructureRightFilters, PlusAdd, ReorderIcon, RoundCheckIcon } from '@reply-pro/component-library'
import AddNewElementModal from './AddNewModal'
import { useState } from 'react'
import { addReportCollection, addReportSection } from 'store/settingsSlice/settingsReducers'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { setReportReordering } from 'store/settingsSlice'
import './manageReports.scss'

export default function ManageReports () {
  const [modalType, setModalType] = useState<string | null>(null)
  const { reportCollectionDetail } = useSelector((state: RootState) => state.settings)
  const { reportingReordering } = useSelector((state: RootState) => state.settings)
  const dispatch: AppDispatch = useDispatch()

  const toggleModal = (type: string | null) => {
    setModalType((prev) => (prev === type ? null : type))
  }

  return (
    <>
      <ManageReportsContextRouter />
      <SettingsPageStructure
        settingsHeader={
          <SettingsHeader
            title='Manage Reports'
            icon={<Reports className='icon-title' />}
            button={
              <Button className='add-collection-button' onClick={() => toggleModal('collection')}>Add New Collection</Button>
            }
          />
        }
        asideChildren={
          <ManageReportsCollections />
        }
        subHeaderTitles={{
          mainSubTitle: (
            <SettingsStructureRightFilters
              searchPlaceholder='Not available yet'
              elementSelected={reportCollectionDetail?.name ?? 'Collection'}
            >
              <>
                <Button onClick={() => toggleModal('section')} stroked noWrap><PlusAdd className='reporting-button-icon' /> Add New Section</Button>
                {reportingReordering !== 'section'
                  ? <Button onClick={() => dispatch(setReportReordering('section'))} stroked noWrap disabled={Boolean(reportingReordering && reportingReordering !== 'section')}>
                    <ReorderIcon className='reporting-button-icon' /> Reorder Sections
                  </Button>
                  : reportingReordering === 'section' &&
                    <Button onClick={() => dispatch(setReportReordering(undefined))} backgroundColor='mainLight' noWrap>
                      <RoundCheckIcon /> Save Order
                    </Button>}
              </>
            </SettingsStructureRightFilters>
          ),
          search: () => '',
          placeHolderSearch: 'Not available yet'
        }}
      >
        <CollectionDetail />
      </SettingsPageStructure>
      <AddNewElementModal
        toggleIsAddNewModalOpen={() => toggleModal(null)}
        isAddNewModalOpen={modalType === 'collection'}
        dispatchAddNew={addReportCollection}
        inputLabel='name of collection'
        title='NEW COLLECTION'
        // eslint-disable-next-line react/jsx-closing-bracket-location
         />
      <AddNewElementModal
        toggleIsAddNewModalOpen={() => toggleModal(null)}
        isAddNewModalOpen={modalType === 'section'}
        dispatchAddNew={addReportSection}
        title='NEW SECTION'
        inputLabel='name of section'
      >
        <span>Sections are used by Report Views to segment your reports and provide ordering capabilities.</span>
      </AddNewElementModal>
    </>
  )
}
